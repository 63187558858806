<template>
  <div></div>
</template>

<script>
import CMS from 'netlify-cms'

const previewStyles = `
  @font-face{
    font-family: Gilroy;
    font-weight: 100 400;
    src: url(/static/fonts/Gilroy-Medium.otf);
  }
  @font-face {
    font-family: Gilroy;
    font-weight: 500 900;
    src: url(/static/fonts/Gilroy-Bold.otf);
  }
  html, body {
    color: #444;
    font-size: 18px;
    font-family: Gilroy, Arial, sans-serif;
  }
  body {
    padding: 20px;
  }
  h1 {
    margin-top: 20px;
    color: #666;
    font-weight: bold;
    font-size: 24px;
  }
  img {
    max-width: 100%;
  }
  div[class*=WidgetPreviewContainer] {
    padding: 5px 0px;
  }
`

export default {
  created () {
    CMS.registerPreviewStyle(previewStyles, { raw: true })
  },

  mounted () {
    CMS.init({ config: this.$config.cms })
  }
}
</script>

<style>
div#nc-root {
  min-height: 100vh;
}

body, h1, h2, h3, h4, h5, h6, p {
  font-family: Gilroy, Arial, sans-serif !important;
}
</style>
